// Description: This file contains the utility functions for the frontend

const PING_INTERVAL = 120000; // 2 mins in milliseconds

/////////////////////////////////////////////////// I. Ping API ///////////////////////////////////////////////////
export const pingApiInterval = async () => {
    const pingApi = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/v3/pings/api'); // Local development - change to prod before deployment
        const response = await fetch('https://twinlab.digilab.co.uk/v3/pings/api'); // Production URL (comment out if working locally)
        const data = await response.json();
        console.log("API data:", data);
        return data; // Return the fetched data
      } catch (error) {
        console.error('Error pinging API:', error);
        return error; // This will actually return the error and update http response to "error" in BlockStatus.tsx, which leads to the red cross icon
      }
    };
  
    // Initial API ping
    const initialData = await pingApi();
  
    // Set interval to ping every 2 minutes
    const interval = setInterval(async () => {
      await pingApi();
      console.log("API response", initialData);
    }, PING_INTERVAL);

  
    return initialData; // Return initial data
  };
  
  /////////////////////////////////////////////////// II. Ping Database ///////////////////////////////////////////////////
  export const pingDatabaseInterval = async () => {
    const pingDatabase = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/v3/pings/database'); // Local development - change to prod before deployment
        const response = await fetch('https://twinlab.digilab.co.uk/v3/pings/database'); // Production URL (comment out if working locally)
        const data = await response.json();
        console.log('Database data', data);
        return data; // Return the fetched data
      } catch (error) {
        console.error('Error pinging Database:', error);
        return error; // This will actually return the error and update http response to "error" in BlockStatus.tsx, which leads to the red cross icon
      }
    };
  
    // Initial API ping
    const initialData = await pingDatabase();
  
    // Set interval to ping every 2 minutes
    const interval = setInterval(async () => {
      await pingDatabase();
      console.log("Database response", initialData);
    }, PING_INTERVAL); 

  
    return initialData; // Return initial data
  };

  /////////////////////////////////////////////////// III. Ping Storage  ///////////////////////////////////////////////////
  export const pingStorageInterval = async () => {
    const pingStorage = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/v3/pings/storage'); // Local development - change to prod before deployment
        const response = await fetch('https://twinlab.digilab.co.uk/v3/pings/storage'); // Production URL (comment out if working locally)
        const data = await response.json();
        console.log('Storage data', data);
        return data; // Return the fetched data
      } catch (error) {
        console.error('Error pinging Storage:', error);
        return error; // This will actually return the error and update http response to "error" in BlockStatus.tsx, which leads to the red cross icon
      }
    };
  
    // Initial API ping
    const initialData = await pingStorage();
  
    // Set interval to ping every 2 minutes
    const interval = setInterval(async () => {
      await pingStorage();
      console.log("Storage response", initialData);
    }, PING_INTERVAL); 

  
    return initialData; // Return initial data
  };


  /////////////////////////////////////////////////// IV. Ping Modal ///////////////////////////////////////////////////
  export const pingModalInterval = async () => {
    const pingModal = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/v3/pings/compute'); // Local development - change to prod before deployment
        const response = await fetch('https://twinlab.digilab.co.uk/v3/pings/compute'); // Production URL (comment out if working locally)
        const data = await response.json();
        console.log('Modal data', data);
        return data; // Return the fetched data
      } catch (error) {
        console.error('Error pinging Modal', error);
        return error; // This will actually return the error and update http response to "error" in BlockStatus.tsx, which leads to the red cross icon
      }
    };
  
    // Initial API ping
    const initialData = await pingModal();
  
    // Set interval to ping every 2 minutes
    const interval = setInterval(async () => {
      await pingModal();
      console.log("Modal response", initialData);
    }, PING_INTERVAL); 
  
    return initialData; // Return initial data
  };
