import React, { useEffect, useState } from 'react';
import BlockStatus from './BlockStatus';

interface BlockConfig {
    apiFunction: () => Promise<{ detail: string }>;
    title: string;
}

interface BlockProps {
    blocks: BlockConfig[];
}

const Block: React.FC<BlockProps> = ({ blocks }) => {
    const [allStatusPassed, setAllStatusPassed] = useState<boolean>(true);

    useEffect(() => {
        // Check if all statuses are "✓"
        const checkAllStatuses = async () => {
            try {
                for (const block of blocks) {
                    const response = await block.apiFunction();
                    if (response.detail !== "pong") {
                        setAllStatusPassed(false);
                        return;
                    }
                }
                setAllStatusPassed(true);
            } catch (error) {
                console.error('Error checking statuses:', error);
                setAllStatusPassed(false);
            }
        };

        checkAllStatuses();
    }, []);

    const generalStatus = allStatusPassed ? "All systems are operational" : "twinLab is currently offline";

    return (
        <div className="block-container">
            <h2 className='generalstatus'>{generalStatus}</h2>
            <h2 className='currentstatus'>Current status:</h2>
            <div className="blocks-grid">
                <div className="block-item">
                    <BlockStatus apiFunction={blocks[0].apiFunction} title={blocks[0].title} />
                </div>
                <div className="block-item">
                    <BlockStatus apiFunction={blocks[1].apiFunction} title={blocks[1].title} />
                </div>
            </div>
            <div className="blocks-grid">
                <div className="block-item">
                    <BlockStatus apiFunction={blocks[2].apiFunction} title={blocks[2].title} />
                </div>
                <div className="block-item">
                    <BlockStatus apiFunction={blocks[3].apiFunction} title={blocks[3].title} />
                </div>
            </div>
            <p className='footnote'>Dealing with an issue? Contact the twinLab product team by email <a href="mailto:twinlab@digilab.co.uk">(twinlab@digilab.co.uk)</a>  <br /> or log an issue in our <a href="https://github.com/digiLab-ai/twinLab-Interface" target="_blank" rel="noopener noreferrer">GitHub repository</a>. </p>
        </div>
    );
};

export default Block;
