import React, {useState, useEffect} from 'react';
import { TailSpin } from 'react-loader-spinner';

interface BlockStatusProps {
    apiFunction: () => Promise<{ detail: string }>;
    title: string;
}

const BlockStatus: React.FC<BlockStatusProps> = ({ apiFunction, title }) => {
    const [httpResponse, setHttpResponse] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const initialData = await apiFunction();
                if (initialData.detail === "pong") {
                    setHttpResponse("pong");
                }
                 else {
                    setHttpResponse("error");
                 }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Determine status base on the response from the API
    const getStatus = () => {
        if (httpResponse === null) {
            return <TailSpin color='#162448' height="24" width="24" />;
        } else if (httpResponse === "pong") {
            return "✓";
        } else if (httpResponse === "error") {
            return "✗";
        }
    };

    return (
        <div className='blockcontainer'>
            <span className='blocktitle'>{title}</span>  <span className='blockresult'>{getStatus()}</span>
        </div>
    );
};

export default BlockStatus;