import './App.css';
// Local
import {pingApiInterval, pingDatabaseInterval, pingStorageInterval, pingModalInterval} from './utils';
import BlockStatus from './components/BlockStatus';
import Block from './components/Block';

function App() {
  const blocks = [
    { apiFunction: pingApiInterval, title: 'API' },
    { apiFunction: pingDatabaseInterval, title: 'Database' },
    { apiFunction: pingStorageInterval, title: 'Storage' },
    { apiFunction: pingModalInterval, title: 'Backend' }];

  return (
    <div className="App">
      <h1 className='twinlab' onClick={() => window.location.href = '/'}>twinLab</h1>
      <Block blocks={blocks} />
    </div>

  );
}

export default App;

